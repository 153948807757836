<template>
    <div class="challenge">
        <div class="page__content-header">
            <PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Results')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
            <div class="page-content__actions" v-if="!['FINISHED', 'CLOSED'].includes(tournament.getStatus())">
                <div class="challenge__interactive">
                    <button v-if="!['FINISHED', 'CLOSED'].includes(tournament.getStatus()) && tournament.getIsChatEnabled()" @click="openTournamentChat">
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.29754 5.63244C3.95476 5.63244 4.48754 5.09966 4.48754 4.44244C4.48754 3.78522 3.95476 3.25244 3.29754 3.25244C2.64033 3.25244 2.10754 3.78522 2.10754 4.44244C2.10754 5.09966 2.64033 5.63244 3.29754 5.63244Z" fill="#00CBCB"/>
                            <path d="M7.13751 5.63244C7.79473 5.63244 8.32751 5.09966 8.32751 4.44244C8.32751 3.78522 7.79473 3.25244 7.13751 3.25244C6.48029 3.25244 5.94751 3.78522 5.94751 4.44244C5.94751 5.09966 6.48029 5.63244 7.13751 5.63244Z" fill="#00CBCB"/>
                            <path d="M10.935 5.63244C11.5922 5.63244 12.125 5.09966 12.125 4.44244C12.125 3.78522 11.5922 3.25244 10.935 3.25244C10.2778 3.25244 9.745 3.78522 9.745 4.44244C9.745 5.09966 10.2778 5.63244 10.935 5.63244Z" fill="#00CBCB"/>
                            <path d="M2.37288 11.9976C2.31452 11.9979 2.2566 11.9878 2.20203 11.9678C2.11643 11.944 2.04087 11.8944 1.98628 11.8263C1.93168 11.7582 1.90087 11.6751 1.89831 11.5889L1.82 8.95518H0.396271C0.132881 8.95518 0 8.66815 0 8.41557V0.45924C0 0.206658 0.132881 0 0.396271 0H13.4163C13.6773 0 14 0.206658 14 0.45924V8.41557C13.9786 8.55553 13.9096 8.68458 13.8038 8.78231C13.6981 8.88004 13.5617 8.94087 13.4163 8.95518H5.63559L2.70983 11.8943C2.66406 11.9331 2.6105 11.9623 2.55251 11.98C2.49453 11.9978 2.43337 12.0038 2.37288 11.9976ZM0.949153 8.0367H2.28271C2.34165 8.03234 2.40089 8.03947 2.45692 8.05768C2.51295 8.07589 2.56463 8.10481 2.60889 8.14272C2.65315 8.18062 2.6891 8.22675 2.71459 8.27835C2.74008 8.32996 2.7546 8.38599 2.75729 8.44313L2.80949 10.4362L5.08746 8.14003C5.13431 8.10072 5.18908 8.07125 5.2483 8.05346C5.30752 8.03568 5.36991 8.02997 5.43153 8.0367H13.0508V0.91848H0.949153V8.0367Z" fill="#00CBCB"/>
                        </svg>
                        <span>chat</span>
                    </button>
                    <button @click="showSelectWinners = true" :title="!isStarted ? 'Select winner available after tournament started' : 'Select winner'" :disabled="!isStarted">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.29998 12.3927L7.31998 12.9002L7.51498 11.8127L6.72998 11.0327L7.82498 10.8852L8.32248 9.89771L8.80248 10.8927L9.89498 11.0602L9.09748 11.8252L9.27498 12.9152L8.29998 12.3927Z" fill="#00CBCB"/>
                            <path d="M8.23001 8.79242C8.09055 8.79201 7.95327 8.7577 7.83001 8.69242L4.61751 7.01742C4.43859 6.91188 4.29144 6.76004 4.19156 6.57789C4.09169 6.39574 4.04279 6.19003 4.05002 5.98242V2.42992C4.05002 1.79492 4.47501 1.29492 5.01752 1.29492H11.6075C12.15 1.29492 12.575 1.79492 12.575 2.42992V5.97742C12.5837 6.18716 12.5347 6.39526 12.4333 6.57908C12.3319 6.76289 12.182 6.91537 12 7.01992L8.61252 8.69992C8.49394 8.76008 8.36297 8.79175 8.23001 8.79242ZM5.08001 2.29242C5.06032 2.33462 5.0509 2.38088 5.05251 2.42742V5.98242C5.04867 6.0382 5.06364 6.09365 5.09501 6.13992L8.23001 7.77242L11.535 6.12992C11.5632 6.0842 11.5772 6.0311 11.575 5.97742V2.42992C11.5766 2.38338 11.5672 2.33712 11.5475 2.29492L5.08001 2.29242Z" fill="#00CBCB"/>
                            <path d="M8.3125 15.0625C7.60791 15.0625 6.91913 14.8536 6.33328 14.4621C5.74743 14.0707 5.29082 13.5143 5.02118 12.8633C4.75154 12.2123 4.68099 11.496 4.81845 10.805C4.95591 10.1139 5.29521 9.47916 5.79343 8.98093C6.29166 8.48271 6.92643 8.14341 7.61749 8.00595C8.30855 7.86849 9.02485 7.93904 9.67581 8.20868C10.3268 8.47832 10.8832 8.93493 11.2746 9.52078C11.6661 10.1066 11.875 10.7954 11.875 11.5C11.8737 12.4444 11.4979 13.3498 10.8301 14.0176C10.1623 14.6854 9.25693 15.0612 8.3125 15.0625ZM8.3125 8.9375C7.80569 8.9375 7.31025 9.08779 6.88885 9.36936C6.46745 9.65093 6.13901 10.0511 5.94506 10.5194C5.75111 10.9876 5.70036 11.5028 5.79924 11.9999C5.89811 12.497 6.14217 12.9536 6.50054 13.312C6.85891 13.6703 7.31551 13.9144 7.81258 14.0133C8.30966 14.1121 8.82489 14.0614 9.29313 13.8674C9.76136 13.6735 10.1616 13.3451 10.4431 12.9236C10.7247 12.5022 10.875 12.0068 10.875 11.5C10.8743 10.8206 10.6041 10.1692 10.1237 9.68877C9.64331 9.20835 8.99192 8.93816 8.3125 8.9375Z" fill="#00CBCB"/>
                        </svg>
                        <span>winner</span>
                    </button>
                </div>
                <ChallengeCloseButton></ChallengeCloseButton>
            </div>
        </div>

        <div class="page__content-body">

            <div class="challenge-matches">
                <p class="form-field-description" v-if="!isStarted">
                    Information will be generated after the start of the tournament
                </p>

                <div class="center__block" v-if="!isStarted">
                    <ChallengeStartButton @start="getTournament(tournament.getId()), getMatches(tournament.getId())"></ChallengeStartButton>
                </div>

                <div class="matches" v-if="isStarted">
                    <ChallengeMatch :match="match" v-for="match in sortMatches" :key="match.id"></ChallengeMatch>
                </div>
            </div>

        </div>

        <ChatsListContainer :hideAdminCalls="true" :id="tournament.getId()" />
        <SelectWinners @close="showSelectWinners = false" v-if="showSelectWinners"></SelectWinners>

    </div>
</template>
<script>
import PageLinks from "../Page/Links";
import ChallengeStartButton from './ChallengeStartButton'
import ChallengeMatch from "./ChallengeMatch";
import ResultsModal from './ResultsModal'
import MatchService from '../../api/match'
import {mapActions, mapMutations, mapGetters} from 'vuex'
import ChatsListContainer from '../../components/Chat/ChatsListContainer'
import ChallengeCloseButton from "./ChallengeCloseButton";
import SelectWinners from './SelectWinners'
import moment from "moment";
export default {
    data () {
        return {
            showSelectWinners: false
        }
    },
    components: {
        ChallengeCloseButton,
        ResultsModal,
        ChatsListContainer,
        PageLinks,
        SelectWinners,
        ChallengeMatch,
        ChallengeStartButton
    },
    methods: {
        async openTournamentChat () {
            const channel = this.tournament.getChatRoom()
            if (!channel) {
                await this.getTournament(this.tournament.getId())
                await this.$nextTick()
            }
            const chat = {
                id: this.tournament.getId(),
                payload: {
                    channel: this.tournament.getChatRoom(),
                    isTournamentChat: true
                }
            }
            await this.tournamentChat(chat)
        },
        ...mapActions({
            getTournament: 'GET_TOURNAMENT',
            tournamentChat: 'OPEN_TOURNAMENT_CHAT',
            getMatches: 'fetchMatches',
            centrifugeConnect: 'centrifugeConnect'
        }),
        ...mapMutations({
            replaceMatch: 'replaceMatch'
        })

    },
    computed: {
        sortMatches () {
          return this.matches.sort((a,b) => {
              if (!a.results[0] && !b.results[0]) {
                  return 0;
              }

              if (a.results[0] && !b.results[0]) {
                  return 1;
              }

              if (b.results[0] && !a.results[0]) {
                  return -1;
              }

              const first = moment(a.results[0].updated_at).valueOf()
              const second = moment(b.results[0].updated_at).valueOf()

              return second - first
          }).reverse()
        },
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance',
            matches: 'matches',
            tournament: 'tournament'
        }),
        isStarted () {
            return ['STARTED', 'FINISHED', 'IN_PROGRESS', 'CLOSED'].includes(this.tournament.getStatus())
        }
    },
    async mounted () {
        await this.centrifugeConnect()
        if (this.isStarted) {
            await this.getMatches(this.tournament.getId())
        }

        if (this.centrifugeInstance) {
            let channel = `result.tournament.${this.tournament.getId()}.match.*`
            this.centrifugeInstance.subscribe(channel, async (data) => {
                this.$root.loaderOff();
                await MatchService.get(this.tournament.getId(), data.data.match_id, true).then(response => {
                    this.replaceMatch({ id: data.data.match_id, match: response.data})
                })
                this.$root.loaderOn();
            })
        }
    }
}
</script>
<style scoped lang="scss">

.challenge {
    .center__block {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 30px 0;
        padding: 50px 0;
        border-top: 1px solid gray;
    }
    .challenge__interactive {
        display: flex;
        margin-right: 15px;
        button {
            background: transparent;
            border: 2px solid #00E7DF;
            border-radius: 2px;
            outline: none;
            padding: 11px 25px;
            cursor: pointer;
            display: flex;
            transition: 0.2s;
            align-items: center;
            span {
                margin-left: 6px;
                letter-spacing: 0.16px;
                text-transform: uppercase;
                font-weight: bold;
                color: #06C2BC;
            }
            &:hover {
                opacity: 0.6;
            }
            &[disabled] {
                opacity: 0.4;
                cursor: not-allowed;
            }
        }
        button + button {
            margin-left: 15px;
        }
    }
    .challenge-matches {
        margin-top: 25px;
        .matches {
            padding-left: 7px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            grid-row-gap: 0;
            padding-right: 7px;
        }
    }
}
</style>
