export default (tournament) => {
	return {
        with_third_place_match: tournament.getWithThirdPlaceMatch(),
		game_id: tournament.getGameId(),
        chat_image_enabled: tournament.getChatImageEnabled(),
        is_chat_enabled: tournament.getIsChatEnabled(),
        available_web: tournament.getAvailableWeb(),
        available_mobile: tournament.getAvailableMobile(),
		platform_id: tournament.getPlatformId(),
		title: tournament.getTitle(),
		description: tournament.getDescription(),
		max_member: tournament.getMaxMembers(),
        chat_link: tournament.getChatLink(),
        notify_mzaalo: tournament.getNotifyMzaalo(),
		site_link: tournament.getSiteLink(),
		image: tournament.getImage(),
        end_at: tournament.getEndAt(),
        type: tournament.getType(),
		started_at: tournament.getStartedAt(),
		check_in_time: tournament.getCheckInTime(),
        auto_check_in: tournament.getAutoCheckIn(),
        rule_id: tournament.getRuleId(),
		match_type: tournament.getMatchType(),
		template_id: tournament.getTemplateId(),
		team_mates: tournament.getTeamMatesCount(),
        challenge_description: tournament.getChallengeDescription(),
		secret_code: tournament.getSecretCode(),
        substitute_limit: tournament.getSubstitutes(),
		region_id: tournament.getRegionId(),
		supported_countries: tournament.getCountry(),
		register_timeout: tournament.getRegisterTimeout(),
		sponsor_image: tournament.getSponsorImage(),
		sponsor_text: tournament.getSponsorText(),
		require_user_profiling: tournament.getRequireUserProfiling(),
        // turq_enabled: tournament.getTurqEnabled(),
		sponsor_url: tournament.getSponsorUrl(),
		match_autohandle: tournament.getMatchAutoHandle(),
		consider_in_leaderboard: tournament.getConsiderInLeaderboard(),
        prize_pool: tournament.getPrizePool()
	}
}
