import moment from 'moment'

export const MAX_FILE_SIZE = 2048;

export const TOURNAMENT_FIELDS_VALUES = {
	game_id: 12,
	platform_id: '',
	title: '',
	description: '',
	// turq_enabled: false,
	max_member: 6,
	fixture_participants: 0,
	chat_link: '',
	site_link: '',
	image: '',
	template_id: '',
    challenge_description: '',
    auto_check_in: false,
    check_in_time: 60,
    end_at: moment().add(30, 'minutes').utc().format(),
	started_at: moment().add(30, 'minutes').utc().format(),
	rule_id: '',
	match_type: 1,
	type: 1,
	rounds: 1,
	team_mates: 1,
	send_promo: false,
	secret_code: '',
	substitute_limit: 0,
	region_id: null,
	supported_countries: [],
	register_timeout: moment().add(30, 'minutes').utc().format(),
	sponsor_image: '',
	sponsor_text: '',
	sponsor_url: '',
	match_autohandle: false,
	consider_in_leaderboard: true,
    is_chat_enabled: false,
    chat_image_enabled: false,
    available_web: true,
    available_mobile: true,
    custom_seeding: false,
    with_third_place_match: false,
    min_lobby_players: '',
    notify_mzaalo: false,
	max_lobby_players: '',
	require_user_profiling: true,
    prize_pool: [
        {
            place: 1,
            prizes: []
        },
        {
            place: 2,
            prizes: []
        },
        {
            place: 3,
            prizes: []
        },
    ],
};

export const LOCALES = [
	{value: null, name: 'Global'},
	{value: 'it', name: 'Italian'},
	{value: 'en', name: 'English'},
	{value: 'eg', name: 'Egypt'},
];

export const MATCH_TYPES_VALUES = [
	{value: 1, name: 'Solo participant'},
	{value: 2, name: 'Team participant'},
];

export const TOURNAMENT_TYPES_VALUES = [
	{value: 1, name: 'Brackets'},
	{value: 2, name: 'Leaderboard'},
    {value: 3, name: 'Double Elimination'},
    {value: 4, name: "Round Robin"},
    {value: 7, name: "Swiss-system"},
    {value: 5, name: 'Arena Multilobby'},
    {value: 6, name: 'TFT'},
    {value: 8, name: 'Challenge'},
];

export const LOCATION_TYPES = [
	{value: 'country', name: 'Country' },
	{value: 'region', name: 'Region'}
];

export const START_STATUS = [
	{value: 'opened', name: 'Open' },
	{value: 'registration', name: 'Registration open'}
];
